import Vue from 'vue'

// axios
import axios from 'axios'

let baseURL = 'http://hieapi.local'
// const customBaseURL = 'http://192.168.100.147'
const customBaseURL = 'http://192.168.100.141'

if (window.location.href.includes('hie.sriphat.local' || 'hie.sriphat')) {
  baseURL = 'http://hieapi.local'
} else {
  baseURL = 'http://hieapi.sriphat.com'
}
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
  baseURL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Content-Type': 'application/json',
  },
})

const customAxiosIns = axios.create({
  baseURL: customBaseURL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    'Content-Type': 'application/json',
  },
})

Vue.prototype.$http = axiosIns
Vue.prototype.$httpCustom = customAxiosIns

export default axiosIns
