/* eslint-disable no-param-reassign */
export default {
  namespaced: true,
  state: {
    otherSelect: [],
    ipdSelect: [],
    mrSelect: [],
    emrSelect: [],
    ipd: [],
  },
  getters: {
    setAllOther: state => {
      const data = state.otherSelect
      return data
    },
    setAllMr: state => {
      const data = state.mrSelect
      return data
    },
    setAllIpd: state => {
      const data = state.ipdSelect
      return data
    },
    setAllEmr: state => {
      const data = state.emrSelect
      return data
    },
    setIpdSelect: state => param => {
      const date = param[0]
      let data = ''
      state.ipdSelect.every(element => {
        if (element[0].date === date) {
          data = element
          return false
        }
        return true
      })
      return data
    },
    // ค้นหาใบยาที่เลือก
    setOtherSelect: state => param => {
      const date = param[0]
      const group = param[1]
      let data = ''
      state.otherSelect.every(element => {
        if (element[0].date === date && element[0].group === group) {
          data = element
          return false
        }
        return true
      })
      return data
    },
    setMrSelect: state => param => {
      const date = param[0]
      const group = param[1]
      let data = ''
      state.mrSelect.every(element => {
        if (element[0].date === date && element[0].group === group) {
          data = element
          return false
        }
        return true
      })
      return data
    },
    setEmrSelect: state => param => {
      let data = ''
      state.emrSelect.every(element => {
        if (element[0].nameImage === param) {
          data = element
          return false
        }
        return true
      })
      return data
    },
  },
  // เก็บใบยาที่เลือก
  mutations: {
    getFromUpdateOther(state, item) {
      let firstGroup = item[0][Object.keys(item[0])[4]]
      let arrayTemp = []
      for (let index = 0; index < item.length; index += 1) {
        delete item[index].id
        delete item[index].rid
        item[index].group = item[index].location
        delete item[index].location
        if (firstGroup === item[index].group) {
          arrayTemp.push(item[index])
        } else {
          firstGroup = item[index].group
          state.otherSelect.push(arrayTemp)
          arrayTemp = []
          arrayTemp.push(item[index])
        }
      }
      state.otherSelect.push(arrayTemp)
    },
    getFromUpdateIpd(state, item) {
      item[0].forEach(element => {
        delete element.id
        delete element.rid
      })
      state.ipdSelect.push(item[0])
      // // console.log('getFromUpdateIpd', state, item)
    },
    getIPDSelect(state, item) {
      const data = item[0]
      let stateIpdIndex = -1
      stateIpdIndex = state.ipdSelect.findIndex(element => element[0].date === item[1][0])
      if (stateIpdIndex !== -1 && data.length !== 0) {
        state.ipdSelect[stateIpdIndex] = data
      } else if (data.length === 0) {
        state.ipdSelect.splice(stateIpdIndex, 1)
      } else {
        state.ipdSelect.push(data)
      }
    },
    getOtherSelect(state, item) {
      const data = item[0]
      let stateOtherIndex = -1
      stateOtherIndex = state.otherSelect.findIndex(element => element[0].date === item[1][0] && element[0].group === item[1][1])
      if (stateOtherIndex !== -1 && data.length !== 0) {
        state.otherSelect[stateOtherIndex] = data
      } else if (data.length === 0) {
        state.otherSelect.splice(stateOtherIndex, 1)
      } else {
        state.otherSelect.push(data)
      }
    },
    getMRSelect(state, item) {
      const data = item[0]
      let stateMrIndex = -1
      stateMrIndex = state.mrSelect.findIndex(element => element[0].date === item[1][0] && element[0].group === item[1][1])
      if (stateMrIndex !== -1 && data.length !== 0) {
        state.mrSelect[stateMrIndex] = data
      } else if (data.length === 0) {
        state.mrSelect.splice(stateMrIndex, 1)
      } else {
        state.mrSelect.push(data)
      }
    },
    getEmrSelect(state, item) {
      const data = item[0]
      const txn = item[1][0]
      let stateEmrIndex = -1
      stateEmrIndex = state.emrSelect.findIndex(element => element[0].nameImage === txn)
      if (stateEmrIndex !== -1 && data.length !== 0) {
        state.emrSelect[stateEmrIndex] = data
      } else if (data.length === 0) {
        state.emrSelect.splice(stateEmrIndex, 1)
      } else {
        state.emrSelect.push(data)
      }
    },
    clearData(state) {
      state.otherSelect = []
      state.ipdSelect = []
      state.mrSelect = []
      state.emrSelect = []
      state.ipd = []
    },
  },
  actions: {
  },
}
