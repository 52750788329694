export default [
  {
    path: '/doctor',
    name: 'doctor-info',
    component: () => import('@/views/doctor/info.vue'),
    meta: {
      pageTitle: 'แพทย์',
      breadcrumb: [
        {
          text: 'ข้อมูล',
          active: true,
        },
      ],
    },
  },
  {
    path: '/doctor/list',
    name: 'doctor-list',
    component: () => import('@/views/doctor/doctors-list/DoctorsList.vue'),
    meta: {
      pageTitle: 'รายชื่อแพทย์',
      breadcrumb: [
        {
          text: 'รายชื่อแพทย์',
          active: true,
        },
      ],
    },
  },
  {
    path: '/doctor/list/:clinic/',
    name: 'doctor-list-in-clinic',
    component: () => import('@/views/doctor/doctors-list/DoctorsList.vue'),
    meta: {
      pageTitle: 'รายชื่อแพทย์',
      breadcrumb: [
        {
          text: 'รายชื่อแพทย์',
          active: true,
        },
      ],
    },
  },
  {
    path: '/doctor/list/:clinic/:filtertype',
    name: 'doctor-list-in-clinic-filter',
    component: () => import('@/views/doctor/doctors-list/DoctorsList.vue'),
    meta: {
      pageTitle: 'รายชื่อแพทย์',
      breadcrumb: [
        {
          text: 'รายชื่อแพทย์',
          active: true,
        },
      ],
    },
  },
  {
    path: '/doctor/schedule',
    name: 'doctor-schedule-search',
    component: () => import('@/views/doctor/schedule/index.vue'),
    meta: {
      pageTitle: 'แพทย์',
      breadcrumb: [
        {
          text: 'ตารางออกตรวจ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/doctor/schedule/:clinic',
    name: 'doctor-schedule',
    component: () => import('@/views/doctor/schedule/calendar.vue'),
    meta: {
      pageTitle: 'แพทย์',
      breadcrumb: [
        {
          text: 'รายชื่อแพทย์',
        },
        {
          text: 'doctorcode',
          active: true,
        },
      ],
    },
  },
  {
    path: '/doctor/schedule/:clinic/:doctorid',
    name: 'doctor-schedule-in-clinic',
    component: () => import('@/views/doctor/schedule/calendar.vue'),
    meta: {
      pageTitle: 'แพทย์',
      breadcrumb: [
        {
          text: 'รายชื่อแพทย์',
          href: '#',
        },
        {
          text: 'doctorcode',
          active: true,
        },
      ],
    },
  },
]
