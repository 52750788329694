export default {
  namespaced: true,
  state: {
    test: '',
  },
  getters: {
    getTest: state => param => state.test + param,
  },
  mutations: {
    setTest(state, item) {
      state.test = item
    },
  },
  actions: {},
}
