export default [
  {
    path: '/sticker',
    name: 'sticker',
    component: () => import('@/views/hie/viewerDocument/sticker.vue'),
  },
  {
    path: '/testMail',
    name: 'testMail',
    component: () => import('@/views/hie/components/testMail.vue'),
  },
  {
    path: '/testPDF',
    name: 'testPDF',
    component: () => import('@/views/hie/components/testPDF.vue'),
  },
  {
    path: '/viewer/:hn/:rid',
    name: 'viewer',
    component: () => import('@/views/hie/viewerDocument/viewerMain/viewer.vue'),
  },
  {
    path: '/viewerUpdate/:hn/:rid',
    name: 'viewerUpdate',
    component: () => import('@/views/hie/viewerDocument/viewerMain/viewerUpdate.vue'),
  },
  {
    path: '/hie/RequestAudit',
    name: 'RequestAudit',
    component: () => import('@/views/hie/MedicalRecordAudit/RequestAudit.vue'),
  },
  {
    path: '/hie/RequestHistory',
    name: 'RequestHistory',
    component: () => import('@/views/hie/MedicalRecordAudit/historyRequest.vue'),
  },
  {
    path: '/hie/MedicalRecordAudit',
    name: 'MedicalRecordAudit',
    component: () => import('@/views/hie/MedicalRecordAudit/Main_MedicalRecord.vue'),
  },
  {
    path: '/hie/addUserInfo',
    name: 'addUserInfo',
    component: () => import('@/views/hie/MedicalRecordAudit/addUserInfo.vue'),
  },
  // {
  //   path: '/hie/record-viewer',
  //   name: 'record-viewer',
  //   component: () => import('@/components/record-viewer/general.vue'),
  // },
  {
    path: '/hie/users/UsersList',
    name: 'UsersList',
    component: () => import('@/views/hie/user/users-list/UsersList.vue'),
  },
  {
    path: '/viewerDisplay/:hn/:rid',
    name: 'viewerDisplay',
    component: () => import('@/views/hie/viewerDocument/viewerMain/viewerDisplay.vue'),
  },
  {
    path: '/manual',
    name: 'manual',
    component: () => import('@/views/hie/manual/manual.vue'),
  },
  {
    path: '/medicalViewer',
    name: 'medicalViewer',
    component: () => import('@/views/hie/viewerDocument/medicalViewer/viewer.vue'),
  },
  {
    path: '/hie/SWC',
    name: 'SWC',
    component: () => import('@/views/hie/MedicalRecordAudit/SWC/medicalRequestSWC.vue'),
  },
  {
    path: '/hie/selectViewer',
    name: 'SelectViewer',
    component: () => import('@/views/hie/viewerDocument/medicalViewer/selectedViewer.vue'),
  },
  {
    path: '/hie/transferFile',
    name: 'transferFile',
    component: () => import('@/views/hie/viewerDocument/medicalViewer/selectRecordMenu.vue'),
  },
]
