export default [
  {
    path: '/nurse',
    name: 'nurse-dashboard',
    component: () => import('@/views/nurses/dashboard/Dashboard.vue'),
  },
  {
    path: '/nurse/patients',
    name: 'nurse-patient-list',
    component: () => import('@/views/nurses/NursePatientsList.vue'),
  },
  {
    path: '/nurse/patients/:clinic',
    name: 'nurse-patient-list-in-clinic',
    component: () => import('@/views/nurses/NursePatientsList.vue'),
  },
  {
    path: '/nurse/patients/:clinic/:doctor',
    name: 'nurse-patient-list-in-clinic-doctor',
    component: () => import('@/views/nurses/NursePatientsList.vue'),
  },
  {
    path: '/nurse/schedule',
    name: 'nurse-schedule',
    component: () => import('@/views/nurses/DoctorSchedule/schedule.vue'),
  },
  {
    path: '/nurse/schedule/:clinic',
    name: 'nurse-schedule-clinic',
    component: () => import('@/views/nurses/DoctorSchedule/schedule.vue'),
  },
  {
    path: '/nurse/schedule/:clinic/:doctor',
    name: 'nurse-schedule-clinic-doctor',
    component: () => import('@/views/nurses/DoctorSchedule/schedule.vue'),
  },
  {
    path: '/nurse/appointment',
    name: 'nurse-appointment',
    component: () => import('@/views/nurses/appointments/AppointmentLists.vue'),
  },
  {
    path: '/nurse/appointment/:clinic',
    name: 'nurse-appointment-clinic',
    component: () => import('@/views/nurses/appointments/AppointmentLists.vue'),
  },
  {
    path: '/nurse/appointment/:clinic/:doctor',
    name: 'nurse-appointment-clinic-doctor',
    component: () => import('@/views/nurses/appointments/AppointmentLists.vue'),
  },
]
