export default [
  {
    path: '/mr',
    name: 'mr-dashboard',
    component: () => import('@/views/mr/dashboard/Dashboard.vue'),
  },
  {
    path: '/mr/lists',
    name: 'mr-list-home',
    component: () => import('@/views/mr/mr.vue'),
    meta: {
    },
  },
  {
    path: '/mr/lists/:TypeList',
    name: 'mr-list',
    component: () => import('@/views/mr/mr.vue'),
    meta: {
    },
  },
  {
    path: '/mr/enroll',
    name: 'mr-enroll',
    component: () => import('@/views/mr/enroll/Enroll.vue'),
    meta: {
    },
  },
]
