const today = new Date()
const day = `0${today.getDate()}`.substr(-2)
const month = `0${today.getMonth() + 1}`.substr(-2)
const year = `${today.getFullYear()}`
const hour = `0${today.getHours()}`.substr(-2)
const minutes = `0${today.getMinutes()}`.substr(-2)
const seconds = `0${today.getSeconds()}`.substr(-2)
export default {
  getCurDateTime() {
    return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`
  },
  getCurTime() {
    return `${hour}:${minutes}`
  },
  getSQLCurDate() {
    return `${year}-${month}-${day}`
  },
  getSQLCurDateTime() {
    return `${year}-${month}-${day}T${hour}:${minutes}:${seconds}`
  },
  getFullDate(dateInput, lang = 'th') {
    const tmp = dateInput.split('-')
    const tmpDay = Number(tmp[2])
    const tmpMonth = Number(tmp[1])
    let tmpYear = Number(tmp[0])
    let monthNames

    if (lang === 'th') {
      monthNames = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม']
      tmpYear = Number(tmpYear) + 543
    } else {
      monthNames = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม']
    }

    return `${tmpDay} ${monthNames[tmpMonth - 1]} ${tmpYear}`
  },
  IntNull(dataInput) {
    if (dataInput === '') {
      return 0
    }
    return parseInt(dataInput, 0)
  },
  FloatNull(dataInput) {
    if (dataInput === '') {
      return 0
    }
    return parseFloat(dataInput, 2)
  },
  TimeNull(dataInput) {
    if (dataInput === '') {
      return '00'
    }
    return dataInput
  },
}
