import Vue from 'vue'
import {
  BootstrapVue, IconsPlugin, ToastPlugin, ModalPlugin,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueMobileDetection from 'vue-mobile-detection'

import i18n from '@/libs/i18n'
import helpers from '@/libs/helper'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

// Helper
const plugin = {
  install() {
    Vue.helpers = helpers
    Vue.prototype.$helpers = helpers
  },
}

Vue.use(plugin)

Vue.use(PerfectScrollbar)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueMobileDetection)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
