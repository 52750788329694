export default {
  namespaced: true,
  state: {
    hieTimeLine: [],
    hieRecordDiscard: [],
    hieRecordType: [],
    hn: 0,
  },
  getters: {
    setHieTimeLine: state => {
      const data = state.hieTimeLine
      return data
    },
    setHieRecordDiscard: state => {
      const data = state.hieRecordDiscard
      return data
    },
    setHieRecordType: state => {
      const data = state.hieRecordType
      return data
    },
    setHn: state => {
      const data = state.hn
      return data
    },
  },
  mutations: {
    getHieTimeLine(state, item) {
      state.hieTimeLine = item
    },
    getHieRecordDiscard(state, item) {
      state.hieRecordDiscard.push(item)
    },
    getHieRecordType(state, item) {
      state.hieRecordType = item
    },
    getHN(state, item) {
      state.hn = item
    },
    clearHieRecordDiscard(state) {
      state.hieRecordDiscard = []
    },
    clearHieRecordType(state) {
      state.hieRecordType = []
    },
  },
  actions: {
    saveRecord({ commit }, item) {
      commit('getHieRecordType', item)
    },
  },
}
